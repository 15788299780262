import React from 'react'
import AboutText from '../ui/AboutText'
import { useTranslation } from 'react-i18next'
import CircleIcon from '../../icons/CircleIcon';

const WhoWe = () => {
    const { t } = useTranslation();
    return (
        <div className=' main-prop padding-container max-container overflow-hidden'>
            <div className='flex-between gap-10 xs:flex-col md:flex-row '>
                <div className=' flex-1'>
                    <AboutText title={t("who-we")} text={t("who-we-content")} />
                </div>
                <div className=' relative flex-1 flex-center flex-col'>
                    <div className='w-1/2 md:h-[340px] rounded-xl overflow-hidden'>
                        <img className='w-full h-full' src="/asset/whowe.jpg" loading='lazy' alt="who-we-image" />
                    </div>
                    <div className='w-5/6 xs:mr-6 md:mr-8 absolute xs:-bottom-8 md:-bottom-16' data-aos="flip-up">
                        <CircleIcon />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhoWe