import { steps } from '../../constant';
import AboutText from '../ui/AboutText'
import { useTranslation } from 'react-i18next';

const Step = () => {

    const { t } = useTranslation();
    return (
        <div className=' main-prop padding-container max-container overflow-hidden'>
            <div className='flex gap-10 flex-col '>
                <div>
                    <AboutText title={t("step-title")} text={t("step-disc")} />
                </div>
                <ul className='  flex flex-col gap-y-10'>
                    {steps.map((step,index)=>(
                        <li data-aos="fade-left" data-aos-delay={`${index+1}00`} key={index} 
                        className={` relative ${index===1?"xs:ms-10 sm:ms-24 md:ms-40":index===2?" xs:ms-20 sm:ms-48 md:ms-80":""} flex  items-center xs:gap-x-0 sm:gap-x-5 `}>
                            <div className='w-fit'>
                            <p className='  bg-secondry xs:text-3xl sm:text-5xl  text-white w-12 sm:w-16  h-12 sm:h-16 rounded-full flex-center font-bold'>{index+1}</p> 

                            </div>
                            <p className='text-xl sm:text-3xl xl:text-4xl ms-2 w-64 sm:w-96 '>{step.text}</p>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default Step