import { useTranslation } from 'react-i18next';
import MainLink from '../ui/MainLink';
import { Swiper, SwiperSlide } from 'swiper/react';
import { products } from '../../constant';
import { Autoplay, Navigation } from 'swiper/modules';

const Brands = () => {
    const { t } = useTranslation();
/*  
//////////////////////////
****** 
only remove this command and import useState , useEffect from react and 
import fetchProducts from ../data and remove this line 
import { products } from '../../constant';
******
//////////////////////////

    const [brands, setBrands] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchProducts();
            setBrands(data.brands);
        }
        fetchData();
    }, []) */
    return (
        <div className='main-prop overflow-hidden'>
            <div className='max-container padding-container  flex-center flex-col'>
                <h2 data-aos="fade-down" className=' font-extrabold xs:text-3xl md:text-4xl pb-16 ' >{t("know-brands")}</h2>
                <div className='w-full relative'>
                    <Swiper
                        slidesPerView={5}
                        loop={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                            },
                            640: {
                                slidesPerView: 2,
                            },
                            768: {
                                slidesPerView: 3,
                            },
                            1024: {
                                slidesPerView: 5,
                            },
                        }}
                        pagination={{ clickable: true }}
                        modules={[Navigation, Autoplay]}>
                        {products.map((product, productIndex) => (
                            <div key={productIndex}>
                                {product.brands.map((img, imgIndex) => (
                                    <SwiperSlide key={`${productIndex}-${imgIndex}`}>
                                        <div className='flex-center py-5' >
                                            <div className=' w-36 h-36 rounded-full overflow-hidden brand-shadow flex-center'>
                                                <img className='w-full' src={img.img} alt="product-brand" />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </div>
                        ))}
                    </Swiper>
                    <div className="swiper-button-next custom-swiper-button"></div>
                    <div className="swiper-button-prev  custom-swiper-button"></div>
                </div>
                <div data-aos="fade-up" className=' pt-16'>
                    <MainLink title={t("discover-all-brands")} href={"#"} />
                </div>
            </div>
        </div>
    )
}

export default Brands