import React from 'react'
import { navlinks } from '../../constant';
import { Link } from 'react-router-dom';
import { IoMdClose } from "react-icons/io"
import { useTranslation } from 'react-i18next';
interface NavbarProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const MobileNavbar = ({ open, setOpen }: NavbarProps) => {

    const { t } = useTranslation();
    return (
        <div className={`fixed bg-[#00000090] z-40 md:hidden xs:flex  h-full top-0 left-0 ${open ? "w-full" : "w-0"}`}>
            <div className={`fixed bg-white z-50 transition-all duration-300  h-full top-0 left-0 py-8 ${open ? "w-4/6 overflow-visible" : "w-0 overflow-hidden"} `}>
                <button type='button' onClick={() => setOpen(false)} title='close menu' className={` ${open ? "flex" : "hidden"}  mx-6  mb-6 border-2 rounded-full text-secondry border-secondry p-1 font-bold`}>
                    <IoMdClose />
                </button>
                <ul className=' flex text-darkColor text-xl font-semibold flex-col py-3 mx-6 gap-5 border-b-2 border-primary '>
                    {navlinks.map((link, index) => (
                        <li key={index} className=' transition-all hover:text-black'>
                            <Link to={link.href} className='w-full text-start'>
                                <button className="w-full text-start" type="button" onClick={() => setOpen(false)}>
                                    {link.name}
                                </button>
                            </Link>
                        </li>
                    ))}
                </ul>
                <div className='pt-6 text-darkColor text-xl font-semibold mx-6'>
                    <Link className=' transition-all duration-300 hover:text-black' to={"#"}>{t("join-us")}</Link>
                </div>
            </div>
        </div>
    )
}

export default MobileNavbar