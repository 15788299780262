import React from 'react'
import { useTranslation } from 'react-i18next'
import MainLink from '../ui/MainLink';
import Car from '../../icons/Car';

const HitunAd = () => {
    const { t } = useTranslation();
    return (
        <div className='main-prop sharp-gradient overflow-hidden'>
            <div className='  padding-container'>
                <div className=' bg-secondry xs:rounded-xl md:rounded-full py-10'>
                    <div className=' py-10 padding-container flex-between xs:flex-col md:flex-row overflow-hidden'>
                        <div className='text-white flex-1'>
                            <h3 className="text-4xl font-bold pb-3">{t("hitun-ad-title")}</h3>
                            <p className=' text-2xl '>{t("hitun-ad-disc")}</p>
                            <div className='pt-7'>
                                <MainLink title={t("join-us")} href={"/contact-us"} />
                            </div>
                        </div>
                        {/* <div className='flex-1 flex-center'>
                            <div data-aos="fade-right">
                                <Car/>        
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HitunAd