
const Map = () => {
    return (
        <div data-aos="fade-up" className='h-96 rounded-5xl overflow-hidden'>
            <a
                href="https://maps.app.goo.gl/sgEatyUg5YjM4r4h6"
                target="_blank" rel="noreferrer" 
                className="w-full h-full "
            >
                <img
                    alt="تواصل معنا" 
                    className="w-full h-full rounded-5xl overflow-hidden object-cover image-link"
                    src="/asset/map2.png"
                    loading="lazy"
                />
            </a>
        </div>
    );
};

export default Map;
