import React, { useEffect, useState } from 'react'
import Logo from '../ui/Logo'
import { navlinks } from '../../constant'
import { Link, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Aos from 'aos'
import { FaBars } from 'react-icons/fa'
import "../../aos.css"
import MobileNavbar from '../ui/MobileNavbar'

const Navbar = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    useEffect(() => {
        Aos.init({
            easing: 'ease-in-out',
            duration: 1000,
        });
    }, [])
    return (
        <header className='bg-white w-full z-50 fixed top-0 shadow-lg'>
            <div className='  max-container padding-container py-4'>
                <div className=' flex-between text-2xl font-semibold text-darkColor'>
                    <Logo />
                    <ul className='flex-center gap-6 xs:hidden md:flex '>
                        {navlinks.map((link, index) => (
                            <li key={index}>
                                <NavLink to={link.href} className=' w-full transition-all duration-300 hover:text-black'>
                                    {link.name}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                    <Link className=' xs:hidden md:flex transition-all duration-300 hover:text-black' to={"#"}>{t("join-us")}</Link>
                    <button type='button' onClick={() => setOpen(true)} title='open menu' className=' xs:flex md:hidden  text-secondry text-[26px]'>
                        <FaBars />
                    </button>
                </div>
            </div>
            <div>
                <MobileNavbar open={open} setOpen={setOpen} />
            </div>
        </header>
    )
}

export default Navbar