import { LinkProps } from '../../types'
import { Link } from 'react-router-dom'


const AltLink = ({ title, href }: LinkProps) => {
    return (
        <div>
            <Link to={`${href}`}
                className=' xs:text-lg sm:text-xl border-2 text-white border-white  px-4 py-2  rounded-full font-semibold  transition-all duration-500 hover:bg-secondry hover:border-secondry'>
                {title}
            </Link>
        </div>
    )
}

export default AltLink