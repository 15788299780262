import React from 'react'
import Hero from './Hero'
import Address from './Address'
import Footer from '../common/Footer'
import Location from './Location'

const Index = () => {
  return (
    <div>
        <Hero/>
        <Address/>
        <Location/>
        <Footer/>
    </div>
  )
}

export default Index