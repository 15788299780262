import React from 'react'
type AboutTextProps = {
    title: string;
    text: string;
}
const AboutText = ({ title, text }: AboutTextProps) => {
    return (
        <div>
            <h1 data-aos="fade-left" data-aos-delay="100" className=' xs:text-6xl md:text-7xl pb-6 font-extrabold '>{title}</h1>
            <p data-aos="fade-left" data-aos-delay="200" className='xs:text-2xl md:text-3xl pb-8'>{text}</p>
        </div>
    )
}

export default AboutText