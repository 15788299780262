import React from 'react'
import Navbar from '../common/Navbar'
import { useTranslation } from 'react-i18next';


const Hero = () => {

    const { t } = useTranslation();
    return (
        <section className='contact-hero h-screen xs:bg-center md:bg-top relative overflow-hidden'>
            <Navbar />
            <div className='h-full flex-center' id='hero'>
                <div className='overlay'></div>
                <div className=' h-full max-container padding-container text-center absolute w-full text-white flex-center flex-col z-20' >
                    <div className=' text-start w-full'>
                        <div>
                            <h1 data-aos="fade-left" className='xs:text-6xl md:text-8xl font-extrabold pb-5'>{t("contact-us")}</h1>
                            <p data-aos="fade-left" data-aos-delay="300"  className='text-2xl pb-2'>{t("contact-us-disc")}</p>
                        </div>
                        
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Hero