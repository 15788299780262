import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './routes/App';
import "./i18n"
import { createBrowserRouter, RouterProvider, } from "react-router-dom";
import Home from './components/Home/Home';
import AboutLayout from './routes/About';
import Contact from './routes/Contact';


const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            { index: true, element: <Home /> },
            { path: "about", element: <AboutLayout /> },
            { path: "contact-us", element: <Contact /> }
        ]
    },
]);
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);
