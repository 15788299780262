import { useTranslation } from "react-i18next"
import { addresses, contact } from "../../constant"
import { IoIosArrowBack } from "react-icons/io";
import { BiSolidMessageRoundedError } from "react-icons/bi";
import { Link } from "react-router-dom";

const Address = () => {
    const {t}=useTranslation();
    return (
        <div className=" main-prop padding-container max-container">
            <div>
            <div className='section-heading flex items-center gap-3 mb-10'>
                    <div className=' flex-center flex-col'>
                        <p className=' contact-icon text-primary text-2xl'><BiSolidMessageRoundedError /></p>
                        <div className='w-10'>
                            <img src="/asset/icon.webp" alt="icon" />
                        </div>
                    </div>
                    <div>
                        <h2 className='text-4xl font-semibold'>{t("main-address")}</h2>
                    </div>
                </div>
                <ul className="grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-14">
                    {addresses.map((address, index) => (
                        <li key={index} data-aos="fade-up" data-aos-delay={`${index+1}00`} 
                        className=" rounded-lg p-5 shadow-md text-2xl">
                            <h2 className="font-bold text-[28px] mb-9">{address.title}</h2>
                            <div className="">
                                {contact.map((item, index) => (
                                    <div key={index} className=" mb-5">
                                        {item.text}
                                    </div>
                                ))}
                            </div>
                            <Link to={"https://goo.gl/maps/MjyZTKQBvbTH1DcD6"} className=" pt-6 font-semibold flex items-center text-primary gap-1">
                                <p>{t("direct")}</p>
                                <IoIosArrowBack />
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default Address