import React from 'react'
import { useTranslation } from 'react-i18next'
import { hitunTeam } from '../../constant';

const HitunTeam = () => {
    const {t}= useTranslation();
  return (
    <div className='main-prop padding-container max-container flex-center flex-col'>
        <h2 className='xs:text-6xl pb-12 font-extrabold'>{t("hitun-team")}</h2>
        <ul className='  padding-container grid lg:grid-cols-3 md:grid-cols-2 xs:grid-cols-1 gap-10'>
            {hitunTeam.map((person,index)=>(
                <li key={index} className=' hitun-team rounded-xl flex-center flex-col py-16 px-4'>
                    <div className='w-5/6'>
                        <img className='w-full' src="/asset/person.jpg" alt="person" loading='lazy' />
                    </div>
                    <h3 className=' text-5xl font-semibold  pb-5'>{person.name}</h3>
                    <p className=' text-3xl text-primary'>{person.position}</p>
                </li>
            ))}
        </ul>
    </div>
  )
}

export default HitunTeam