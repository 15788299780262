
const CircleIcon = () => (
  <svg
    className="w-11/12 md:w-9/12 lg:w-10/12 2xl:w-full"
    viewBox="0 0 760 173"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M714.125 3.1409C669.383 24.0519 622.31 39.5874 573.895 49.4209C510.36 62.8914 445.56 69.5334 380.606 69.2336C315.26 69.5215 250.065 62.9312 186.105 49.5721C137.729 39.4508 90.6773 23.8209 45.8756 2.98962C38.7813 -0.292543 30.7342 -0.894793 23.2285 1.29469C15.7227 3.48416 9.26843 8.31663 5.06407 14.8947C0.85972 21.4728 -0.808977 29.3494 0.367777 37.0622C1.54453 44.7749 5.48679 51.7996 11.4626 56.8318C56.0779 92.9606 106.943 120.64 161.546 138.502C232.039 161.823 305.885 173.471 380.152 172.985C454.393 173.47 528.205 161.716 598.606 138.2C653.17 120.569 704 93.0349 748.537 56.983C754.513 51.9509 758.456 44.9262 759.632 37.2134C760.809 29.5007 759.14 21.624 754.936 15.046C750.732 8.4679 744.277 3.63544 736.772 1.44596C729.266 -0.743519 721.219 -0.141269 714.125 3.1409Z"
      fill="#FAD059"
    />
  </svg>
);
export default CircleIcon;
