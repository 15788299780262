import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import ScrollToTop from "../components/ui/ScrollToTop";
function App() {
    const {i18n} = useTranslation();
    return (
        <div dir={i18n.language === "ar" ? "rtl" : "ltr"}>
        <ScrollToTop />
            <Outlet/>
        </div>
    );
}
export default App;
