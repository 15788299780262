import React from 'react'
import { useTranslation } from 'react-i18next';
import AboutText from '../ui/AboutText';
import { vision } from '../../constant';

const Vision = () => {
    const { t } = useTranslation();
    return (
        <div className='main-prop padding-container max-container overflow-hidden'>
            <div className='bg-primary main-prop text-white padding-container rounded-5xl flex flex-col xs:gap-10 md:gap-20'>
                <div>
                    <AboutText title={t("our-vision")} text={t("our-vision-text")} />
                </div>
                <div>
                    <ul className=' flex text-black xs:pb-0   gap-16 justify-between xs:flex-col md:flex-row xs:px-6 md:px-20 lg:px-0'>
                        {vision.map((item,index)=>(
                            <li data-aos="fade-up" data-aos-delay={`${index+2}00`} key={index} className={`relative bg-white h-fit rounded-xl p-10 flex-1 ${index===1?" md:mt-40":""} `}>
                                <h4 className='text-4xl font-bold py-5'>{item.title}</h4>
                                <p className='text-2xl'>{item.text}</p>
                                <p className={`absolute p-2 rounded-xl -top-7 -right-7 text-6xl bg-secondry text-white `}>{item.icon}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Vision