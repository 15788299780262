import { useTranslation } from 'react-i18next';
import MainLink from '../ui/MainLink';
import SectionText from '../ui/SectionText';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {  Navigation, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Link } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { Product } from '../../types';
import { products } from '../../constant';
const Products = () => {
    const { t } = useTranslation();
/*  
//////////////////////////
****** 
only remove this command and import useState , useEffect from react and 
import fetchProducts from ../data and remove this line 
import { products } from '../../constant';
******
//////////////////////////


    const [products, setProducts] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchProducts();
            setProducts(data);
            console.log(products)
        }
        fetchData();
    }, []) */
    return (
        <div className='main-prop overflow-hidden'>
            <div className=' max-container padding-container flex xs:flex-col lg:flex-row gap-10'>
                <div className='xs:w-full lg:w-1/3'>
                    <SectionText number={'02'} title={t("our-products")} text={t("our-product-disc")} />
                    <div data-aos="fade-left"  data-aos-delay="300">
                        <MainLink title={t("discover-product")} href={"/"} />
                    </div>
                </div>
                <div className='xs:w-full lg:w-2/3 products-container py-20 px-10 rounded-3xl relative'>
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={1}
                        loop={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                        pagination={{ clickable: true }}
                        modules={[Navigation, Autoplay]}>
                        {products.map((product:Product, index:number) => (
                            <SwiperSlide key={index}>
                                <div className='py-10 border-b-[1px]'>
                                    <h3 className=' text-5xl pb-5 font-extrabold'>{product.name}</h3>
                                    <p className=' text-xl text-darkColor '>{product.description}</p>
                                    <Link to={"/"} className=' flex pt-8 items-center text-2xl font-bold gap-2 text-primary'>
                                        {t("show-more")}
                                        <IoIosArrowBack />
                                    </Link>
                                </div>
                                <div className='flex gap-10 py-6'>
                                    {
                                    product.brands.map((brand, index:number)=>(
                                        <div key={index} className='w-36'>
                                            <img className='w-full' src={brand.img} alt="product-brand" />
                                        </div>
                                    ))
                                    }
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className=' absolute w-32 top-14 left-5'>
                        <div className="swiper-button-next custom-swiper-button"></div>
                        <div className="swiper-button-prev  custom-swiper-button"></div>
                    </div>
                    <div data-aos="flip-up" className=' absolute -bottom-10 xs:right-5 lg:-right-10'>
                        <img className='w-full' src="/asset/icon.webp" alt="main-icon" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Products