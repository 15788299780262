import AboutUs from "./AboutUs"
import Brands from "./Brands"
import ContactUs from "./ContactUs"
import Footer from "../common/Footer"
import Hero from "./Hero"
import Products from "./Products"


const Home = () => {
  return (
    <div>
        <Hero/>
        <Brands/>
        <AboutUs/>
        <Products/>
        <ContactUs/>
        <Footer/>
    </div>
  )
}

export default Home