import React from 'react'
import AboutText from '../ui/AboutText'
import { useTranslation } from 'react-i18next';
import { ability } from '../../constant';

const Position = () => {
    const { t } = useTranslation();
    return (
        <div className='main-prop overflow-hidden padding-container max-container flex flex-col gap-10'>
            <div className=' xs:w-full md:w-1/2'>
                <AboutText title={t("our-position")} text={t("our-position-text")} />
            </div>

            <ul className=' grid gap-16 sm:grid-cols-3 xs:grid-cols-1'>
                {ability.map((item, index) => (
                    <li key={index} className=' flex-center flex-col'>
                        <p data-aos="zoom-in" data-aos-delay={`${index+1}00`} className=' flex-center w-20 h-20 bg-[#e8f7cb] rounded-full text-primary text-5xl  '>{item.icon}</p>
                        <div className='flex-center flex-col' data-aos="fade-up" data-aos-delay={`${index+1}00`} >
                            <p className=' text-6xl font-semibold py-4'>{item.number}</p>
                            <p className=' text-darkColor text-2xl '>{item.text}</p>
                        </div>

                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Position