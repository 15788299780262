import SectionText from '../ui/SectionText'
import { useTranslation } from 'react-i18next';
import MainLink from '../ui/MainLink';
import { contact } from '../../constant';
import Map from '../ui/Map';

const ContactUs = () => {
    const { t } = useTranslation();
    return (
        <div className='main-prop overflow-hidden'>
            <div className=' max-container padding-container'>
                <div>
                    <SectionText number={'03'} title={t("contact-us")} text={t("contact-us-disc")} />
                    <div data-aos="fade-left" data-aos-delay="300">
                    <MainLink title={t("contact-us")} href={"#"} />
                    </div>
                </div>
                <div className='my-10'>
                    <Map/>
                </div>
                <ul className=' flex justify-between gap-5 xs:flex-col md:flex-row py-6'>
                    {contact.map((item, index) => (
                        <li data-aos="fade-up" data-aos-delay={`${index+1}00`} key={index} className='flex items-center gap-3'>
                            <div className=' flex-center flex-col'>
                                <p className=' contact-icon text-primary text-2xl'>{item.icon}</p>
                                <div className='w-10'>
                                    <img src="/asset/icon.webp" alt="icon" />
                                </div>
                            </div>
                            <div>
                                <h5 className='text-2xl font-semibold'>{item.title}</h5>
                                <p className='text-lg'>{item.text}</p>
                            </div>
                        </li>
                    ))}
                </ul>
            </div >
        </div >
    )
}

export default ContactUs