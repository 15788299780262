import React from 'react'
import Hero from './Hero'
import Footer from '../common/Footer'
import WhoWe from './WhoWe'
import HitunTeam from './HitunTeam'
import Position from './Position'
import Vision from './Vision'
import Step from './Step'

const Index = () => {
  return (
    <div>
        <Hero/>
        <WhoWe/>
        <HitunTeam/>
        <Position/>
        <Vision/>
        <Step/>
        <Footer/>
    </div>
  )
}

export default Index