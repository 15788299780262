import React from 'react'
import Navbar from '../common/Navbar'
import { useTranslation } from 'react-i18next';
import MainLink from '../ui/MainLink';
import AltLink from '../ui/AltLink';

const Hero = () => {

    const { t } = useTranslation();
    return (
        <section className='hero h-screen xs:bg-center md:bg-top relative overflow-hidden'>
            <Navbar />
            <div className='h-full flex-center' id='hero'>
                <div className='overlay'></div>
                <div className=' h-full max-container padding-container text-center absolute w-full text-white flex-center flex-col z-20' >
                    <div className=' text-start w-full'>
                        <div>
                            <h1 data-aos="fade-left" className='xs:text-6xl md:text-8xl font-extrabold pb-5'>{t("hero-title-1")}</h1>
                            <h1 data-aos="fade-left" data-aos-duration="1100" className=' xs:text-6xl md:text-8xl font-extrabold pb-8'>{t("hero-title-2")}</h1>
                            <p data-aos="fade-left" data-aos-delay="300"  className='text-2xl pb-2'>{t("hero-disc")}</p>
                            <p data-aos="fade-left" data-aos-delay="300" className='text-2xl '>{t("hero-disc-1")}</p>
                        </div>
                        <div data-aos="fade-up" data-aos-delay="500" className=' xs:flex-col sm:flex-row mt-10 w-fit flex gap-10'>
                            <MainLink title={t("discover-brands") } href={"#brands"}/>
                            <AltLink title={t("about") } href={"/about"}/>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Hero