import React from 'react'
import SectionText from '../ui/SectionText'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { about } from '../../constant';
import { IoIosArrowBack } from 'react-icons/io';

const AboutUs = () => {

    const { t } = useTranslation();
    return (
        <div className='main-prop overflow-hidden'>
            <div className=' max-container padding-container'>
                <div className=' md:w-1/2 xs:w-full'>
                    <SectionText number={'01'} title={t("who-we")} text={t("who-we-text")} />
                    <Link data-aos="fade-left" data-aos-delay="300" to={"/about"} className=' flex  items-center text-2xl font-bold gap-2 text-primary'>
                        {t("about")}
                        <IoIosArrowBack />
                    </Link>
                </div>
                <div className=' main-prop'>
                    <ul className=' grid gap-y-14  gap-x-5 xs:grid-cols-2 md:grid-cols-4'>
                        {about.map((item, index) => (
                            <li className=' relative flex-center flex-col rounded-3xl xs:py-4 md:py-12 text-white bg-primary' key={index}>
                                <p data-aos="fade-up" data-aos-delay="100" className=' xs:text-6xl md:text-8xl mb-3 font-extrabold'>{item.number}</p>
                                <p data-aos="fade-up" data-aos-delay="200" className=' xs:text-xl md:text-3xl '>{item.title}</p>
                                <p data-aos="zoom-in" className= 'xs:text-2xl md:text-4xl  absolute z-10 -top-10 bg-secondry rounded-full xs:p-4 md:p-5'>{item.icon}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>

    )
}

export default AboutUs