import { MdAdsClick, MdHouse } from "react-icons/md";
import { FaWhatsapp, FaPhone, FaInstagram, FaTruck, FaTwitter } from 'react-icons/fa';
import { FaUserGroup } from "react-icons/fa6";
import { TfiCup } from "react-icons/tfi";
import { BiLogoFacebook, BiSolidMessageRoundedDots, BiSolidMessageRoundedError } from "react-icons/bi";
import { WiSnowflakeCold } from "react-icons/wi";
import { TbBulb } from "react-icons/tb";
import { IoStorefront } from "react-icons/io5";
import { PiHandshakeThin } from "react-icons/pi";


export const socialMediaLinks = [
    {href: "https://www.facebook.com/people/%D8%B4%D8%B1%D9%83%D8%A9-%D8%BA%D8%B2%D8%A7%D9%84%D8%A9-%D8%A7%D9%84%D8%AE%D9%8A%D8%B1/61559580471348/", icon: <BiLogoFacebook /> },
    {href: "tel:218945004447", icon: <FaWhatsapp /> },
    { href: "https://x.com/WAlmanfi59913", icon: <FaTwitter /> }
];

export const navlinks = [
    { name: "الرئيسية", href: "/" },
    { name: "حول", href: "/about" },
    { name: "المركز الاعلامي", href: "#footer" },
    { name: "العلامات التجارية", href: "#brands" },
    { name: "تواصل معنا", href: "/contact-us" },
]


export const about = [
    {
        number: "6+",
        title: "سنة خبرة",
        icon: <MdHouse />
    },
    {
        number: "100+",
        title: "موظف مسجل",
        icon: <FaUserGroup />
    },
    {
        number: "30+",
        title: "علامة تجارية",
        icon: <TfiCup />
    },
    {
        number: "6",
        title: "مراكز توزيع",
        icon: <MdAdsClick />
    }
];
export const products = [
    {
        name: "روابي",
        description: "",
        brands: [
            { img: " /asset/products/001.jpg" },
            // { img: " /asset/indus.webp" },
        ]
    },
    {
        name: "Cheetos",
        description: "",
        brands: [
            { img: " /asset/products/002.jpg" },
            // { img: " /asset/talia.webp" }
        ]
    },
    {
        name: "Dannone",
        description: "",
        brands: [
            { img: " /asset/products/003.png" },
            // { img: " /asset/auer.webp" }
        ]
    },
    {
        name: "Doratos",
        description: "",
        brands: [
            { img: " /asset/products/004.png" },
            // { img: " /asset/nuts.webp" }
        ]
    },
    {
        name: "Lay's",
        description: "",
        brands: [
            { img: " /asset/products/005.png" },
            // { img: " /asset/gran.webp" }
        ]
    },
    {
        name: "Good day",
        description: "",
        brands: [
            { img: " /asset/products/006.jpg" },
            // { img: " /asset/Phila.svg" },
            // { img: "/asset/logo-hajdusajt.webp" }
        ]
    },
    {
        name: "صن بايتس",
        description: "",
        brands: [
            { img: " /asset/products/007.jpg" },
            // { img: " /asset/rirrer.webp" },
            // { img: " /asset/zaini.webp" }
        ]
    },
    {
        name: "بورفكس",
        description: "",
        brands: [
            { img: " /asset/products/008.jpg" },
            // { img: " /asset/rirrer.webp" },
            // { img: " /asset/zaini.webp" }
        ]
    },
];


export const contact = [
    {
        title: "العنوان",
        text: "بوعطني شارع الألبان",
        icon: <BiSolidMessageRoundedError />
    },
    {
        title: "الهاتف",
        text: "+218945004447",
        icon: <FaPhone />
    },
    {
        title: "البريد الالكتروني",
        text: "info@alghazala.ly",
        icon: <BiSolidMessageRoundedDots />
    }
]
export const importantLinks = [
    { title: "الرئيسية" },
    { title: "سياسة الخصوصية" },
    { title: "شروط الاستخدام"},
]
export const hitunTeam=[
    {name:"السيد/  عبد الونيس المنفي",position:"المدير العام"},
    {name:"السيد/ حمدي المنفي",position:"المدير التنفيذي"},
]

export const ability=[
    { number:"120",text:"مركبة توزيع",icon:<FaTruck />},
    { number:"5",text:"مساحة تبريد",icon:<WiSnowflakeCold />},
    { number:"11",text:"مستودع للمواد الغذائية",icon:<IoStorefront />},
]
export const vision=[
    { title:"الالتزام",text:"نلتزم دائماً بقيمنا ومعاييرنا الأساسية في كيفية التعامل مع عملائنا وطرق الوصول إليهم، وتوفير كل مايحتاجه المستهلك لتوفير أفضل تجربة لزبائننا",icon:<PiHandshakeThin />},
    { title:"الإبداع",text:"نهتم بالإبداع والابتكار على كل المستويات، ونؤمن بأهمية البحث عن إمكانيات وحلول جديدة لتحقيق النجاح.",icon:<TbBulb /> }
]

export const steps=[
    {text:"نضع خطة واضحة طويلة الاجل ونلتزم بكل بنودها من أجل تحقيقها"},
    {text:"نعتمد على كوادر وطنية مهنية تضع كل طاقاتها ومهاراتها من اجل نجاح الخطة"},
    {text:"نهتم بكل تفاصيل السوق من اجل ضمان سلامة وصحة المستهلكين"}
]

export const addresses=[
    {title:"الفرع الرئيسي"},
    // {title:"المخزن"},
    // {title:"نقل البضائع"}
]