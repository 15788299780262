import React from 'react'
import { useTranslation } from 'react-i18next';
import HitunAd from './HitunAd';
import { contact, importantLinks, navlinks, products, socialMediaLinks } from '../../constant';
import { Link } from 'react-router-dom';
import HitunIcon from '../../icons/HitunIcon';
import CircleIcon from '../../icons/CircleIcon';
import MMtech from '../ui/MMtech';

const Footer = () => {
    const { t } = useTranslation();
    return (
        <div className=' bg-primary text-white overflow-hidden'>
            <HitunAd />
            <div className=' flex justify-between gap-10 md:flex-row xs:flex-col padding-container max-container text-2xl'>
                <div className='xs:pb-10 sm:pb-0'>
                    <HitunIcon/>
                    <p className='text-2xl pt-5 pb-2'>{t("hero-disc")}</p>
                    <ul className=' text-xl flex flex-col py-3'>
                        {contact.map((item, index) => (
                            <li key={index} className='flex items-center gap-1'>
                                <h5 >{item.title}:</h5>
                                <p>{item.text}</p>
                            </li>
                        ))}
                    </ul>
                    <div className=' text-xl flex gap-5  py-3'>
                        {socialMediaLinks.map((item, index) => (
                            <Link data-aos="fade-in" data-aos-delay={`${index+1}00`} to={item.href} key={index} className='flex-center flex-col gap-y-1'>
                                <p className='bg-white rounded-full text-secondry w-7 h-7 flex-center'>{item.icon}</p>
                                <div className=' w-8'>
                                    <CircleIcon />
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
                <div className=' w-full grid gap-y-10 pb-10 grid-cols-3 xs:hidden sm:grid'>
                    <div className=' lg:ps-10 xs:ps-0'>
                        <h3 className=' font-bold text-3xl pb-7'>{t("hitun")}</h3>
                        <ul className='flex gap-3 flex-col'>
                            {navlinks.map((link, index) => (
                                <li key={index}>
                                    <Link to={link.href} className=' w-full text-gray-100'>
                                        {link.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        <h3 className=' font-bold text-3xl pb-7'>{t("categories")}</h3>
                        <ul className='flex gap-3 flex-col text-gray-100'>
                            {products.map((product, index) => (
                                <li className='' key={index}>{product.name}</li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        <h3 className=' font-bold text-3xl pb-7'>{t("important-links")}</h3>
                        <ul className='flex gap-3 flex-col'>
                            {importantLinks.map((link, index) => (
                                <Link to={"/"} key={index} className=' text-gray-100'>{link.title}</Link>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <MMtech/>
        </div>
    )
}

export default Footer