import React from 'react'
import { Link } from 'react-router-dom'
import HitunLogo from '../../icons/HitunLogo'

const Logo = () => {
  return (
    <Link to={"/"} className=' w-28'>
        <img className='xs:hidden sm:block w-full' src='/asset/website/logo.png' width={40} alt='logo' loading='lazy'/>
        {/* <div className='sm:hidden xs:block'>
          <HitunLogo/>
        </div> */}
    </Link>
  )
}

export default Logo